import React from 'react'
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import Locationmap from './img/locationmap.jpg'

function Locationpop() {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div className="Gallerybox">
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >
                <a href={Locationmap} onClick={() => this.setState({ isOpen: true })} >
                <button  className="btn-11">
   <span>View Location map <em>+</em></span>
  </button>       
                </a>
            </LightGallery>
        </div>
    );
}
export default Locationpop;