import React from 'react';
import Header from './components/Header';
import { Enquire } from './components/Enquire';
import Effect from './Effect';
export const Project_details = () => {
  return (
    <>
          <Effect />   
              <div className='projectdetailsbtn'>                         
                <Enquire />
              </div>
              <div className='effectheader'>
               <Header />
              </div>
    </>
  )
}
export default Project_details;