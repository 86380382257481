import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export const Enquire = () => {
    const [enquireform, setActive] = useState("false");
          
  const handleToggle = () => {
    // document.getElementById("project_name").value = window.location.href;
    setActive(!enquireform);
  };

  const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_f83iarp', 'template_7kzoaje', form.current, 'zFVpQJRnxkJtq4FwX')
        .then((result) => {
            console.log(result.text);
            document.getElementById("submit_button").disabled = true;
            document.getElementById("submit_button_text").innerHTML = "Submitting...";
            alert("Thank you for submitting your enquiry. Our experts will contact you shortly.");
            document.location.reload();
        }, (error) => {
            console.log(error.text);
        });
    };

  return (
    <>
    <div className='projenquire'>
    <div className='wrap'>
  <button  className="btn-11" onClick={handleToggle}>
   <span>Enquire</span>
  </button>
  </div>
  </div>
  <form ref={form} onSubmit={sendEmail}>
    <div className={enquireform ? 'enquireform' : 'enquireform active'}>
    <div className='enquirecontainer'>
            <span className='enquirecross' onClick={handleToggle}>X</span>
                              <h2>Request A Call Back</h2>
                              <ul>
                                <li><input type="text" placeholder='Name *' name="name" required/></li>
                                <li><input type="email" placeholder='Email *' name="email" required/></li>
                                <li><input type="tel" placeholder='Phone Number *' name="phone" required pattern="\d{10}"/></li>
                                {/* <li><input type="hidden" name="project_name" id="project_name" value=""/></li> */}
                              </ul>
                              <button type='submit' className='btn-11' id="submit_button"><span id="submit_button_text">Submit</span></button>
                            </div>
                            </div>
                            </form>
                            </>
  )
}
