import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import Banner01 from './img/alpha-banner.jpg'
import Banner02 from './img/alpha-banner01.jpg'
import Banner03 from './img/aminnetis-new.jpg'
import Banner04 from './img/alpha-banner03.jpg'
import Banner06 from './img/alpha-banner04.jpg'
import Alphalogo from './img/alpha-white.png'
import Residance from './img/alpha-residences.jpg'
import Gal01 from './img/gallery01.jpg'
// import Gal02 from './img/gallery02.jpg'
// import Gal03 from './img/gallery03.jpg'
import Gal04 from './img/gallery04.jpg'
import Gal05 from './img/gallery05.jpg'
import Gal06 from './img/gallery06.jpg'
import Locationpop from './Locationpop';
import Slider from "react-slick";
import VisibilitySensor from 'react-visibility-sensor';
import { Services } from './Services';
import { Link, NavLink } from 'react-router-dom';
import Jujharfile from './img/Jujhar-realty.pdf'
import Projectlogo from './img/jujhar-realty.png'
import { Footer } from './components/Footer';


// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
  // require('./statics/fullpage.scrollHorizontally.min');
};

export const Effect = () => (
  
      <ReactFullpage
    pluginWrapper = {pluginWrapper}
    navigation
    anchors={["Home", "Township", "Amenities", "Residences", "Gallery", "Location", "contact","","" ]}
    //fullpage options
    // licenseKey = {'YOUR_KEY_HERE'}
    scrollingSpeed = {1500} /* Options here */
    scrollHorizontally = {true}  /* Because we are using the extension */
    scrollHorizontallyKey = {'YOUR KEY HERE'}

    render={({ state, fullpageApi }) => {    
      var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        speed: 800,
        autoplaySpeed:8000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
      return (
        <>        
        <ReactFullpage.Wrapper>       
               
          <div className="section" id='home'>
            <div className='proheader'>
              <div className='container'>
                  <div className='row'>   
                                   
                        <div className='col-6'>
                          <div className='prologo'>
                            <NavLink to='/'><img src={Alphalogo}  className="whitelogo"/></NavLink>
                          </div>
                        </div>
                        
                        <div className='col-6'>
                          <div className='prologo d-flex justify-content-end text-end'>
                            <a><img src={Projectlogo}/></a>
                            </div>
                        </div>

                     </div>
              </div>
            </div>
          <div className='projectscroll first-stn'>
                  <img src={Banner01} />
                  <div className='sectioncont'>
                  <h1>Alpha International City <br />World-Class Township in Amritsar</h1>
                  </div>
                  </div>
            <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>
          </div>         
          <div className="section" id='township'>
          <div className='projectscroll first-stn'>
                  <img src={Banner02} />
                  <div className='top-title'>
                    <h2>The Township</h2>
                  </div>
                  <div className='projectscont'>
                  <div class="details">
	            <h4>Where dreams turn into reality</h4> 
	            <p>	                  
              <span>Located on NH1</span><span>Spread over 350+ acres</span><span>Gated Township</span><span>Breathtaking Landscaping</span>
	            </p>
	              </div>
                  </div>
                  </div>
                  <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>
          </div>
          <div className="section" id='amenities'>
          <div className='projectscroll first-stn'>
                  <img src={Banner03} />
                  <div className='top-title'>
                    <h2>The AMENITIES</h2>
                  </div>
                  <div className='projectscont'>
                  <div class="details">
                  <h4>Setting new standards for living</h4> 
	            <p>	                  
	                <span>Shopping Complexes </span><span> Independent Commercial Zone </span><span> Healthcare Facilities </span><span> School</span>
                  <span> LUXURY Sports Facilities </span><span> Luxurious Community Club</span> <span>24x7 CCTV Surveillance </span>
                  <span>  Lush Independent and Central Green Areas  </span><span>  24x7 Power Backup  </span><span>  Community Center  </span><span>  Temple & Gurudwara </span> 
	            </p>
	              </div>
                  </div>
                  </div>
                  <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>
          </div>
          <div className="section" id='residences'>
          <div className='projectscroll first-stn'>
                  <img src={Residance} />
                  <div className='top-title'>
                    <h2>The Residence</h2>
                  </div>
                  <div className='projectscont'>
                  <div class="details">
                  <h4>FEATURING THE BEST EXPERIENCE POSSIBLE</h4> 
	            <p>	                  
	                <span>Luxury independent floors</span><span> Over 300 families already residing  </span><span> 150 feet wide roads </span><span> 24X7 CCTV surveillance</span>
	            </p>
	              </div>
                  </div>
                  </div>
                  <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>
          </div>
          <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
          <div className="section blackdownarrow" id='gallery'>
          <div className='projectscroll first-stn galstn'>
                  <div className='gallerycontainer'>
                  <div className='top-title backcolor'>
                    <h2>Gallery</h2>
                  </div>
                  <div className='gallery projectgal'>
                  {isVisible ? 
                  <Slider {...settings}>
       <div>
          <div className='galbanner'>
            <img src={Gal01} />
               </div>
        </div>
        <div>
          <div className='galbanner'>
            <img src={Gal04} />
               </div>
        </div>
        <div>
          <div className='galbanner'>
            <img src={Gal05} />
               </div>
        </div>
        <div>
          <div className='galbanner'>
            <img src={Gal06} />
               </div>
        </div>      
       
        </Slider>
        : '' }
        </div>
                  </div>
                 
                  </div>
                  <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>          
          </div>
          )}
          </VisibilitySensor>
          <div className="section" id='location'>
          <div className='projectscroll first-stn'>
                  <img src={Banner04} />
                  <div className='top-title'>
              <h2>THE Location</h2>
            </div>
                  <div className='projectscont'>
                  <div class="details">
	            <h4>THE CONVENIENT LOCATION</h4> 
	            <p><span>10 min from Amritsar City Center</span><span>20 min from the Golden Temple</span><span>30 min from Sri Guru Ram Dass Jee International Airport</span><span>Within close proximity to reputed Schools and Hospitals</span></p>
	              </div>
                  </div>
                  </div>
                  <button onClick={() => fullpageApi.moveSectionDown()} className="proarrow">
            <div class="downarrow"><a><span className="animated bounce"></span></a></div>
            </button>
          </div>
          <div className='section profooter'>
          <Services />
                  <Footer />
          </div>
        </ReactFullpage.Wrapper>
        </>
      );
    }}
  />
);
export default Effect;