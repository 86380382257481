import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export const Number = () => {
      const [state, setState] = useState({val1:1, val2:1, val3:1, val4:1, val5:1, val6:1, val7:1, val8:1, val9:1, val10:1});
          return (
            <>
              <section id='numbers'>
                 <div className="numbers">
                    <div className="container">
                        <div className="row">
                        <div className='title' >
                        <h2 data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">Jujhar Group in numbers</h2></div>                                                      
                        </div>
                    </div>
                       <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
          {({ isVisible }) => (
                    <div className='numberbanner col-12'>
                      <div className='container'>
                        <div className='web-container'>
                        <div className="count d-flex flex-wrap">
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.workforce===1) ? 1 : 3000} end={3000} duration={2} onEnd={()=> setState({ workforce:3000 })} /> : <CountUp start={1} end={3000} duration={0} />} +                
             <div className='labelcont'>
          workforce
        </div>
            </div>     
            
      </div>
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.states===1) ? 1 : 8} end={8} duration={2} onEnd={()=> setState({ states:8 })} /> : <CountUp start={1} end={9} duration={0}/>} +     
             <div className='labelcont'>
          states
        </div>
            </div>     
            
      </div>
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.cities===1) ? 1 : 300} end={300} duration={3} onEnd={() => setState({cities:300})}/> : <CountUp start={1} end={300} duration={0} />} +
             <div className='labelcont'>
              Cities
             </div>
            </div>
      </div>
      <div className="App">
            <div className="countnum">
            {isVisible ? <CountUp start={(state.experience===1) ? 1 : 30} end={30} duration={3} onEnd={()=> setState({setState:30})} /> : <CountUp start={1} end={30} duration={0} />} +
            <p>years</p>
             <div className='labelcont'>
              Experience
             </div>
            </div>     
            
      </div>
      
      </div>   
      </div>            
      </div>
      
                        </div>
                        
            )}
        </VisibilitySensor>
                <div className='container'>
                    <div className='web-container'>
                        <div className='row'>
                                <div className='col-12 text-center visitbtn' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                <div className="wrap d-flex justify-content-center">
                            <a href='https://www.jujhargroup.com/' className="btn-11" target='_blank' rel="noreferrer"><span>Visit Jujhar Group Website</span></a>
                        </div>   
                                </div>
                        </div>
                    </div>
                </div>
        </div></section>
            </>
        )
};

export default Number;