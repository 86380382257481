import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const Contactform = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_f83iarp', 'template_cttj429', form.current, 'zFVpQJRnxkJtq4FwX')
        .then((result) => {
            console.log(result.text);
            document.getElementById("submit_button").disabled = true;
            document.getElementById("submit_button_text").innerHTML = "Submitting...";
            alert("Thank you for submitting your enquiry. Our experts will contact you shortly.");
            document.location.reload();
        }, (error) => {
            console.log(error.text);
        });
    };
  return (
    <>
         <section id="contactus" className='pb-0'>
        <div className="enquireform2">
            <div className="container">
                <div className="row">
                    <div className="web-container">
                        <div className="footerform" data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                        <div className="form">
                        <h2>Get in touch</h2>
                        <form ref={form} onSubmit={sendEmail}>
                        <ul>
                                <li>
                                    <input type="text" name="name" placeholder='Name' pattern="[a-zA-Z\s]+" required/>
                                </li>
                                <li>
                                    <input type="tel" name="phone" placeholder='Phone' pattern="^[6-9]\d{9}$" minLength="10" maxLength="10" required/>
                                </li>
                               <li><input type="email" name="email" placeholder='Email' required/></li>
                               <li className='btn-11'><input type="submit" value="" id="submit_button"/><span id="submit_button_text">SUBMIT</span></li>
                            </ul>
                    </form>
                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
