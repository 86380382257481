import React from 'react'
import Jujharlogo from '../img/jujhar-group.png'


export const Footer = () => {
  return (
    <>
    <footer>
        <div className="container">
            <div className="row">
                <div className="footer" data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                    <div className="ftrbox">
                        <img src={Jujharlogo} alt="Jujhar Group" />
                    </div>
                    <div className="ftrbox">
                        <ul>
                            <li>Call</li>
                            <li><a href='tel:+911614044444'>+91-161-4044444</a></li>
                        </ul>
                    </div>
                    <div className="ftrbox">
                        <ul>
                            <li>Email</li>
                            <li><a href='mailto:corporate@jujhargroup.com'>corporate@jujhargroup.com </a></li>
                        </ul>
                    </div>
                     <div className="ftrbox address">
                        <ul>
                            <li>Address</li>
                            <li>Jujhar Corporate Office</li>
                            <li>Level 5, Grand Walk <br/> Ferozepur Road <br/> Ludhiana 141012, Punjab</li>
                        </ul>
                    </div>  
                     <div className="ftrbox">
                        <ul>
                            <li>Follow</li>
                            <li><a href='https://www.instagram.com/thejujhargroup/' rel="noreferrer" target='_blank'>Instagram</a></li>
                            <li><a href='https://www.facebook.com/JujharGroup/' rel="noreferrer" target='_blank'>Facebook</a></li>
                            <li><a href='https://www.linkedin.com/company/jujhargroup/'>LinkedIn</a></li>
                            <li><a href='https://twitter.com/jujhargroup' rel="noreferrer" target='_blank'>Twitter</a></li>
                            <li><a href='https://www.youtube.com/@jujhargroup8530' rel="noreferrer" target='_blank'>Youtube</a></li>
                        </ul>
                    </div>  
                </div>
                <div className="copyrights">
                    <p>&copy; 2023 Jujhar Group | all rights reserved.</p>
                    <p><a href="https://triverseadvertising.com/" rel="noreferrer" target="_blank" >design : triverse</a></p>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}
