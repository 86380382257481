import React from 'react'
import Slider from "react-slick";
import Service01 from './img/Service01.png'
import Service02 from './img/Service02.png'
import Service03 from './img/Service03.png'
import Service04 from './img/Service04.png'

export const Services = () => {
  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    effect:'fade',
    speed: 800,
    autoplaySpeed:8000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 680,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
  return (
    <section id='services'>
        <div className='services'>
              <div className='container'>
                <div className='row'>
                    <div className='web-container'>
                      <div className='title'>
                          <h2 data-aos="zoom-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">Services</h2>
                      </div>
                      <div className='servicestn'>  
      <div className='serviceslider'>
      <Slider {...settings}>
       
      <div>
        <div className='servicebox'>
                              <div className='serviceimg'>
                                <img src={Service02}  alt=''/> 
                                <h4>Residential Developments</h4>                            
                                </div>
                          </div>
        </div>                      
                        <div>
                          <div className='servicebox'>
                              <div className='serviceimg'>
                                <img src={Service03}  alt=''/> 
                                <h4>Work Spaces </h4>                            
                                </div>
                          </div>
                          </div>
                          <div>
                          <div className='servicebox'>
                              <div className='serviceimg'>
                                <img src={Service04}  alt=''/> 
                                <h4>High Street Malls </h4>                            
                                </div>
                          </div>
                          </div>
                          <div>
                          <div className='servicebox'>
                              <div className='serviceimg'>
                                <img src={Service01}  alt=''/> 
                                <h4>Hotels</h4>                            
                                </div>
                          </div>
                          </div>
        </Slider>
      </div>
                </div>
              </div>
              </div>
              </div>
        </div>
    </section>
  )
}
